<template>
  <b-card>
    <h3 class="my-2">
      Voters    </h3>
    <b-table
      ref="VotersTable"
      :items="ticketsData"
      :fields="columns"
      aria-busy="true"
      class="position-relative"
      responsive
      show-empty
      bordered
      primary-key="id"
    />

  </b-card>
</template>

<script>
import axios from 'axios'
import { ref } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'

export default {
  components: {
  },

  setup() {
    const { router } = useRouter()
    const ticketsData = ref({})
    const columns = [
      { key: 'user_name', label: 'User Name' },
      { key: 'image', label: 'Image' },

    ]

    return {
      columns,
      router,
      ticketsData,
    }
  },
  mounted() {
    this.viewticketsData(this.$route.params.id)
  },
  methods: {

    async viewticketsData(id) {
      if (id) {
        await axios.get(`admin/tickets/${id}`).then(res => {
          if (res.status === 200) {
            this.ticketsData = res.data?.data.voters
          }
        })
      }
    },

  },
}
</script>

  <style scoped>
  .padd span {
    padding: 2px;
  }
  .change-status {
    cursor: pointer;
  }
  </style>
